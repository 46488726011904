import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c6166aa4 = () => interopDefault(import('../pages/access-limit/index.vue' /* webpackChunkName: "pages/access-limit/index" */))
const _524b15ba = () => interopDefault(import('../pages/analysis-request/index.vue' /* webpackChunkName: "pages/analysis-request/index" */))
const _7f94e632 = () => interopDefault(import('../pages/close/index.vue' /* webpackChunkName: "pages/close/index" */))
const _474978c4 = () => interopDefault(import('../pages/create-shorturl/index.vue' /* webpackChunkName: "pages/create-shorturl/index" */))
const _08706123 = () => interopDefault(import('../pages/create-shorturl-List/index.vue' /* webpackChunkName: "pages/create-shorturl-List/index" */))
const _4f9d9976 = () => interopDefault(import('../pages/delete-plan/index.vue' /* webpackChunkName: "pages/delete-plan/index" */))
const _1b92fd8a = () => interopDefault(import('../pages/delete-setting/index.vue' /* webpackChunkName: "pages/delete-setting/index" */))
const _89c7872c = () => interopDefault(import('../pages/env-setting/index.vue' /* webpackChunkName: "pages/env-setting/index" */))
const _b0323cee = () => interopDefault(import('../pages/exam-end/index.vue' /* webpackChunkName: "pages/exam-end/index" */))
const _0675efc9 = () => interopDefault(import('../pages/examinees/index.vue' /* webpackChunkName: "pages/examinees/index" */))
const _0b087918 = () => interopDefault(import('../pages/examining/index.vue' /* webpackChunkName: "pages/examining/index" */))
const _0129d3ca = () => interopDefault(import('../pages/identification/index.vue' /* webpackChunkName: "pages/identification/index" */))
const _1a2526e1 = () => interopDefault(import('../pages/identification-ai/index.vue' /* webpackChunkName: "pages/identification-ai/index" */))
const _b223cd00 = () => interopDefault(import('../pages/iframe/index.vue' /* webpackChunkName: "pages/iframe/index" */))
const _7b39e0fe = () => interopDefault(import('../pages/inquiry/index.vue' /* webpackChunkName: "pages/inquiry/index" */))
const _6e32c33a = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _601e6586 = () => interopDefault(import('../pages/logins/index.vue' /* webpackChunkName: "pages/logins/index" */))
const _676f5f23 = () => interopDefault(import('../pages/logview/index.vue' /* webpackChunkName: "pages/logview/index" */))
const _5c2b8bdf = () => interopDefault(import('../pages/management/index.vue' /* webpackChunkName: "pages/management/index" */))
const _a8eb94b8 = () => interopDefault(import('../pages/monitoring/index.vue' /* webpackChunkName: "pages/monitoring/index" */))
const _ae5da7fc = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _83278710 = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _0bf76b01 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _3becf0ca = () => interopDefault(import('../pages/alerting/system-error.vue' /* webpackChunkName: "pages/alerting/system-error" */))
const _16147853 = () => interopDefault(import('../pages/checkers/monitoring/index.vue' /* webpackChunkName: "pages/checkers/monitoring/index" */))
const _008707ec = () => interopDefault(import('../pages/alerting/_id.vue' /* webpackChunkName: "pages/alerting/_id" */))
const _46819586 = () => interopDefault(import('../pages/create-shorturl/_id.vue' /* webpackChunkName: "pages/create-shorturl/_id" */))
const _6dcffe34 = () => interopDefault(import('../pages/examinees/_id/index.vue' /* webpackChunkName: "pages/examinees/_id/index" */))
const _3777a14b = () => interopDefault(import('../pages/examinees/_id/login.vue' /* webpackChunkName: "pages/examinees/_id/login" */))
const _214c466e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/access-limit",
    component: _c6166aa4,
    name: "access-limit"
  }, {
    path: "/analysis-request",
    component: _524b15ba,
    name: "analysis-request"
  }, {
    path: "/close",
    component: _7f94e632,
    name: "close"
  }, {
    path: "/create-shorturl",
    component: _474978c4,
    name: "create-shorturl"
  }, {
    path: "/create-shorturl-List",
    component: _08706123,
    name: "create-shorturl-List"
  }, {
    path: "/delete-plan",
    component: _4f9d9976,
    name: "delete-plan"
  }, {
    path: "/delete-setting",
    component: _1b92fd8a,
    name: "delete-setting"
  }, {
    path: "/env-setting",
    component: _89c7872c,
    name: "env-setting"
  }, {
    path: "/exam-end",
    component: _b0323cee,
    name: "exam-end"
  }, {
    path: "/examinees",
    component: _0675efc9,
    name: "examinees"
  }, {
    path: "/examining",
    component: _0b087918,
    name: "examining"
  }, {
    path: "/identification",
    component: _0129d3ca,
    name: "identification"
  }, {
    path: "/identification-ai",
    component: _1a2526e1,
    name: "identification-ai"
  }, {
    path: "/iframe",
    component: _b223cd00,
    name: "iframe"
  }, {
    path: "/inquiry",
    component: _7b39e0fe,
    name: "inquiry"
  }, {
    path: "/login",
    component: _6e32c33a,
    name: "login"
  }, {
    path: "/logins",
    component: _601e6586,
    name: "logins"
  }, {
    path: "/logview",
    component: _676f5f23,
    name: "logview"
  }, {
    path: "/management",
    component: _5c2b8bdf,
    name: "management"
  }, {
    path: "/monitoring",
    component: _a8eb94b8,
    name: "monitoring"
  }, {
    path: "/privacy",
    component: _ae5da7fc,
    name: "privacy"
  }, {
    path: "/redirect",
    component: _83278710,
    name: "redirect"
  }, {
    path: "/terms",
    component: _0bf76b01,
    name: "terms"
  }, {
    path: "/alerting/system-error",
    component: _3becf0ca,
    name: "alerting-system-error"
  }, {
    path: "/checkers/monitoring",
    component: _16147853,
    name: "checkers-monitoring"
  }, {
    path: "/alerting/:id?",
    component: _008707ec,
    name: "alerting-id"
  }, {
    path: "/create-shorturl/:id?",
    component: _46819586,
    name: "create-shorturl-id"
  }, {
    path: "/examinees/:id",
    component: _6dcffe34,
    name: "examinees-id"
  }, {
    path: "/examinees/:id/login",
    component: _3777a14b,
    name: "examinees-id-login"
  }, {
    path: "/",
    component: _214c466e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
