// import ENV_SETTING_TERM_OF_USE_TEXT_JA from '../const/ja/ENV_SETTING_TERM_OF_USE_TEXT';
// import ENV_SETTING_TERM_OF_USE_TEXT_EN from '../const/en/ENV_SETTING_TERM_OF_USE_TEXT';
// import ENV_SETTING_NOTES_AND_AGREEMENTS_TEXT_JA from '../const/ja/ENV_SETTING_NOTES_AND_AGREEMENTS_TEXT';
// import ENV_SETTING_NOTES_AND_AGREEMENTS_TEXT_EN from '../const/en/ENV_SETTING_NOTES_AND_AGREEMENTS_TEXT';

export enum JapaneseLanguageEnum {
  // COMMON
  HEADER_CLOSE = 'この画面を閉じる',
  FOOTER_TERM = '利用規約',
  FOOTER_PRIVACY_PLICY = 'プライバシーポリシー',
  FOOTER_CONTACT = 'お問い合わせ',
  FOOTER_NOTE = '※この画面はブックマーク、リロード（再表示）などをしないようにしてください',
  LOADING_ALERT_UPLOADING = '動画のアップロード中です。しばらくお待ちください。',
  STEPBAR_ENV_SETTING = '環境設定',
  STEPBAR_AGREE = '規約同意',
  STEPBAR_IDENTITY = '本人認証',
  STEPBAR_EXAMING = '受験開始',
  ACTOR_TESTER = '受験者',
  ACTOR_CHECKER = '監視者',
  ACTOR_TEST_MANAGER = '試験管理者',
  ACTOR_SYSTEM_MANAGER = 'システム管理者',
  ACTOR_SUPERVISOR = '監督官',
  CONFIRM_LOGOUT = 'ログアウトします。よろしいでしょうか？',
  CONFIRM_LOGOUT_CHECKER = 'マッチング中の受験者がいる場合、接続が切断されます。ログアウトを行ってもよろしいでしょうか？',
  CONFIRM_WINDOW_CLOSE = 'タブを閉じます。よろしいでしょうか？',
  FORCE_LOGOUT = '強制退出されました。ログアウトします。',
  FORCE_CLOSE_PAGE = '強制退出されました。画面を閉じてください',
  MANAGEMETN_LINK_BACK = '戻る',
  // ログイン画面
  LOGIN = 'ログイン',
  LOGIN_ID = 'ログインID',
  LOGIN_PASSWORD = 'パスワード',
  LOGIN_FORGET_ID = 'IDをお忘れの方',
  LOGIN_FORGET_PASSWORD = 'パスワードをお忘れの方',
  LOGIN_USE_ENVIRONMENT_CHECK = '利用環境チェック',
  LOGIN_USE_ENVIRONMENT_CHECK_MESSAGE = '利用環境チェックページへアクセスして正しく使用できる状態か確認してください。',
  LOGIN_USE_CAMERA_DEVICE = '利用カメラデバイス：',
  LOGIN_NOT_EXISTS_CAMERA_DEVICE = 'カメラデバイスはありません',
  // リダイレクト
  REDIRECT_TITLE = '', // 見えると変なので空文字にしておく
  // iframe
  IFRAME_TITLE = 'iframe',
  // カメラ・マイク設定画面
  ENV_SETTING_CAMERA_CHECK = 'カメラ設定のチェック',
  ENV_SETTING_MICROPHONE_CHECK = 'マイク設定のチェック',
  ENV_SETTING_POPUP_CHECK = ' ブラウザポップアップのチェック',
  ENV_SETTING_NEXT = '次へ',
  ENV_SETTING_NETWORK_ERROR = 'ネットワークエラーが発生しました。もう一度「次へ」ボタンを押してください。',
  ENV_SETTING_TERM_OF_USE = '利用規約(最後までお読みになり「利用規約に同意します」にチェックしてください）',
  ENV_SETTING_AGREE_TERM_OF_USE = '利用規約に同意します',
  ENV_SETTING_RECONFIRM = '再確認',
  ENV_SETTING_CHROME_NOT_CLICK_RELOAD = '上部に表示されている「再読み込み」ボタンはクリックしないでください。',
  // 本人認証画面
  IDENTIFICATION_CHAT_LABEL = '監視者コメント',
  IDENTIFY_CHECK_LIST_LABEL = 'チェック項目',
  IDENTIFY_CHECK_ITEM_LICENSE = '顔と身分証明証が映るように配置してください。',
  IDENTIFY_CHECK_ITEM_PENDING = '本人認証中',
  IDENTIFY_CHECK_ITEM_FINISHED = '本人認証完了',
  IDENTIFY_CHECK_LIST_NEXT = '次へ',
  IDENTIFY_CHECK_LIST_REJECTED = '本人認証できませんでした。',
  IDENTIFY_CHECK_LIST_LOGOUT = 'ログアウト',
  IDENTIFICATION_CHAT_SEND_YES = 'はい',
  IDENTIFICATION_CHAT_SEND_NO = 'いいえ',
  IDENTIFICATION_WAIT_MATCHNG = 'ただいま、監視者の割り当てをおこなっております。しばらくお待ちください。',
  IDENTIFICATION_AFTER_MATCHNG = 'ただいま監視者が認証のための準備を行っておりますので、しばらくお待ちください。',
  IDENTIFICATION_DISCONNECT_NETWORK = 'ネットワークが切断されています。',
  // AI本人認証画面
  AI_IDENTIFICATION_AUTH_IMAGE = '本人確認画像',
  AI_IDENTIFICATION_ID_IMAGE = 'ID画像',
  AI_IDENTIFICATION_TAKE_PHOTO = '撮影',
  AI_IDENTIFICATION_REGISTRATION = 'この画像を登録',
  AI_IDENTIFICATION_RETAKE_PHOTO = '撮り直し',
  AI_IDENTIFICATION_REGISTRATION_ALERT = 'この写真でいいですか?',
  AI_IDENTIFICATION_CHECK_LIST_FOR_CHECKER = '監視者による本人認証へ進む',

  // AI本人認証画面 - チェックリスト固定文言
  AI_IDENTIFICATION_FIXED_STR_1 = '顔を配置してください',
  AI_IDENTIFICATION_FIXED_STR_2 = '顔を撮影してください',
  AI_IDENTIFICATION_FIXED_STR_3 = '顔画像確認中',
  AI_IDENTIFICATION_FIXED_STR_4 = '顔画像確認完了',
  AI_IDENTIFICATION_FIXED_STR_5 = '身分証を配置してください',
  AI_IDENTIFICATION_FIXED_STR_6 = '身分証を撮影してください',
  AI_IDENTIFICATION_FIXED_STR_7 = '身分証画像確認中',
  AI_IDENTIFICATION_FIXED_STR_8 = '身分証画像確認完了',
  AI_IDENTIFICATION_FIXED_STR_9 = '本人認証完了',

  // AI本人認証画面 - 注意書き（固定文言）
  AI_IDENTIFICATION_FIXED_NOTE_1 = 'サングラスやマスク等をとり、枠の中に顔を入れて撮影ボタンを押してください。',
  AI_IDENTIFICATION_FIXED_NOTE_2 = '枠の中でできるだけ大きく写るように身分証を入れて撮影ボタンを押してください。',
  AI_IDENTIFICATION_FIXED_NOTE_3 = '確認完了',
  AI_IDENTIFICATION_FIXED_NOTE_4 = 'ただいま、身分証画像の確認をおこなっております。しばらくお待ちください。',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_1 = '再撮影してください',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_2 = '顔が判別できません。再度、顔を入れて撮影ボタンを押してください(残りn回)。撮り直しはN回までです。S',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_3 = '身分証が判別できません。再度、身分証を入れて撮影ボタンを押してください(残りn回)。撮り直しはN回までです。S',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_4 = 'ご本人かどうかの確認ができませんでした。再度、顔画像の撮影からやり直してください\n(残りn回)。撮り直しはN回までです。S',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_5 = '所定の取り直し回数を超えた、あるいは撮影した画像と身分証明書の自動認証ができませんでした。監視員認証を行います',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_6 = '所定の取り直し回数を超えた、あるいは撮影した画像と身分証明書の自動認証ができませんでした。ログアウトします。',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_7 = '所定の取り直し回数を超えた、あるいは撮影した画像と身分証明書の自動認証ができませんでした。本日撮影した画像を後日、試験管理者が確認し、その結果、ご本人様と確認できない場合は、確認のためご連絡させていただく場合があります。このまま試験に進んでください。',
  AI_IDENTIFICATION_FIXED_NOTE_SUBSTR_1 = '強制ログアウトされます',
  AI_IDENTIFICATION_FIXED_NOTE_SUBSTR_2 = '監視者による本人認証画面に進みます',
  AI_IDENTIFICATION_FIXED_NOTE_SUBSTR_3 = '所定の撮り直し回数を超えると、最後に撮影した画像を後日、試験管理者が確認します。その結果、ご本人様と確認できない場合は、確認のためご連絡させていただく場合があります。',

  // 利用規約画面
  TERMS_TITLE = '利用規約',
  // 試験中画面
  EXAMINING_TERM = '注意事項',
  EXAMINING_TERM_EXP_TEXT = '以下の注意事項を確認し、同意の上、試験システムにログインしてください。',
  EXAMINING_TERM_NOT_HEADER = '受験開始の画面の注意事項',
  EXAMINING_TERM_NOTES = `
試験開始から終了まで、席を離れることはできません。
受験中は、ヘッドフォン、イヤホンは禁止です。
試験監督からの指示がある場合は音声ではなく、画面に表示されます。
試験監督から画面上に指示があった場合は、その指示に従ってください。
受験中に試験監督から持ち込み可能物、使用可能物に関しての指摘があった場合、試験実施団体から許可されているものである場合はその旨をマイクを通じて申告してください。
受験者からの音声は、試験官は聞くことができます。
受験中に、受験者から試験官に連絡をとることはできません。
試験官は常時監視しているとは限りませんが、後日録画映像を確認することで不正の有無を確認します。
疑義があった場合など確認のため後日連絡させていただくことがあります。
試験終了時に試験官からの指示は特にありません。試験画面に終了メッセージが表示されたら、画面の案内に従い、画面を閉じて終了してください。
  `,
  EXAMINING_TERM_AGREE = '上記注意事項に同意します',
  EXAMINING_ALERT_LABEL_TEXT = '監視中です。この画面は閉じないでください。',
  EXAMINING_LOGIN_TEST_SYSTEM = '試験システムへログイン',
  EXAMINING_INTERRUPTION = '試験中断',
  EXAMINING_END = '試験終了',
  EXAMINING_CLOSE = 'このタブを閉じてください',
  EXAMINING_RECORDING_BEGIN = '録画開始前',
  EXAMINING_RECORDING_AFTER = '録画終了',
  EXAMINING_SHOW_SUMMARY = 'サマリ表示',
  EXAMINING_LOGOUT = 'ログアウト',
  EXAMINING_MODAL_TITLE = '確認',
  EXAMINING_MODAL_AUTH_ERROR_TEXT = '認証エラーが発生したので、監視を終了します。',
  EXAMINING_MODAL_TIMEOUT_TEXT = '録画時間過ぎたのでログアウトしました。',
  // 試験終了画面
  EXAM_END_TERM = '監視情報',
  EXAM_END_LOGOUT = 'ログアウト',
  EXAM_END_ALERT_TEXT_FORMER = 'お疲れ様でした。監視は終了します。',
  EXAM_END_ALERT_TEXT_LATTER = 'ログアウトボタンより終了してください。',
  EXAM_END_MARKING_ALERT_TEXT = '不正と見なされる可能性がある行為が、監視者より報告されました。',
  EXAM_END_ALERT_TEXT_CAUTION_OF_DOWNLOAD = '右クリック→「名前を付けて保存」でダウンロードしてください',
  // 監視画面
  MONITOR_TESTER_STATUS_LOGIN = 'ログイン済',
  MONITOR_TESTER_STATUS_MATCHED = 'マッチング済',
  MONITOR_TESTER_STATUS_IDENTIFICATED = '本人認証済',
  MONITOR_TESTER_STATUS_BEFORE_EXAM = '試験前',
  MONITOR_TESTER_STATUS_EXAMING = '試験中',
  MONITOR_TESTER_STATUS_INTERRUPTION_EXAM = '試験中断',
  MONITOR_TESTER_STATUS_FINISH_EXAM = '試験終了',
  MONITORING_VOLUME_ON = '音声をオンにする',
  MONITORING_VOLUME_OFF = '音声をオフにする',
  MONITOR_NO_CONNECT = '未 接 続',
  MONITOR_NO_ALLOCATION = '割当禁止',
  MONITORING_RECONNECT = '再接続',
  MONITORING_CHAT_LABEL = 'さんへのコメント',
  MONITORING_CHAT_SEND_PLACEHOLDER = 'メッセージを送信',
  MONITORING_CHAT_SEND = '送信',
  MONITORING_SHOW_HISTORY = '履歴表示',
  MONITORING_KICK_OUT = '強制退出',
  MONITORING_KICK_OUT_CONFIRM = '強制退出させます。よろしいですか？',
  MONITORING_AUTH_LABEL = '本人認証',
  MONITORING_AUTH_ACCEPT = '承認',
  MONITORING_AUTH_REJECTED = '却下',
  MONITORING_AUTH_REJECTED_DONE = '却下済み',
  MONITORING_AUTH_MODAL_TITLE = '本人確認画像保存',
  MONITORING_AUTH_MODAL_CANCEL = 'キャンセル',
  MONITORING_AUTH_MODAL_DONE = '実行',
  MONITORING_MARKING_MODAL_CANCEL = 'キャンセル',
  MONITORING_MARKING_MODAL_DONE = '実行',
  MONITORING_MARKING_MODAL_TITLE = '監視情報保存・通知設定',
  MONITORING_MARKING_MODAL_NOTIFY_SETTING = '通知設定',
  MONITORING_MARKING_MODAL_NOTIFY = '受験者に通知する',
  MONITORING_MARKING_MODAL_NOT_NOTIFY = '受験者に通知しない',
  MONITORING_HISTORY_SHOW = '履歴表示',
  MONITORING_HISTORY_MODAL_TITLE = '履歴',
  MONITORING_HISTORY_MODAL_CLOSE = '閉じる',
  MONITORING_HISTORY_MODAL_TABLE_HEADER_LOGIN_ID = 'ログインID',
  MONITORING_HISTORY_MODAL_TABLE_HEADER_NAME = '名前',
  MONITORING_HISTORY_MODAL_TABLE_HEADER_STATUS = 'ステータス',
  MONITORING_HISTORY_MODAL_TABLE_HEADER_UPDATED_AT = '更新日時',
  MONITORING_HISTORY_ERROR_MESSAGE = 'マッチング履歴の取得に失敗しました。',
  // マーキングタイムライン
  MARKING_TIMELINE_COUNT_LABEL = '不正報告件数：',
  MARKING_TIMELINE_SCORE_LABEL = '不正点数：',
  MARKING_TIMELINE_COUNT = '件',
  MARKING_TIMELINE_SCORE = '点',
  MARKING_TIMELINE_DOWNLOAD = 'ダウンロード',
  // 結果検索条件
  EXAMINEES_CONDITIONS_HEADER_TITLE = '検索条件',
  EXAMINEES_CONDITIONS_HEADLINE_TITLE = 'テスト結果',
  EXAMINEES_CONDITIONS_GROUP = '試験団体',
  EXAMINEES_CONDITIONS_GROUP_CODE = '試験団体コード',
  EXAMINEES_CONDITIONS_GROUP_ALERT = '※試験団体名を選択または試験団体コードを入力(両方の指定は不可)',
  EXAMINEES_CONDITIONS_TEST_NAME = '試験名',
  EXAMINEES_CONDITIONS_REGION = '試験会場',
  EXAMINEES_CONDITIONS_TEST_AT = '試験日時',
  EXAMINEES_CONDITIONS_TEST_AT_DATETIME = '試験日時',
  EXAMINEES_CONDITIONS_TEST_AT_DATE_FROM = '試験日FROM',
  EXAMINEES_CONDITIONS_TEST_AT_DATE_TO = '試験日TO',
  EXAMINEES_CONDITIONS_LOGIN_ID = 'ログインID',
  EXAMINEES_CONDITIONS_EXAM_NAME = '受験者名',
  EXAMINEES_CONDITIONS_IS_LIKE_SEARCH = 'あいまい検索',
  EXAMINEES_CONDITIONS_RECORD = '動画データ',
  EXAMINEES_CONDITIONS_WITH_MARK = 'マーキング',
  EXAMINEES_CONDITIONS_CHEATING_LEVEL = '不正レベル',
  EXAMINEES_CONDITIONS_SCORE = '不正点数（>=）',
  EXAMINEES_CONDITIONS_AI_ANALYSIS_FLAG = 'AI解析結果（手動）',
  EXAMINEES_CONDITIONS_AI_ANALYSIS_BATCH_FLAG = 'AI解析結果（自動）',
  EXAMINEES_CONDITIONS_AI_NAME_MATCH = '氏名適合率（<=）',
  EXAMINEES_CONDITIONS_MARK_ID = 'マーキング種別',
  EXAMINEES_CONDITIONS_YES = 'あり',
  EXAMINEES_CONDITIONS_NO = 'なし',
  EXAMINEES_CONDITIONS_BUTTONS_CLEAR = '検索条件をクリア',
  EXAMINEES_CONDITIONS_BUTTONS_SEARCH = 'この条件で検索',
  EXAMINEES_TABLE_CSV_DOWNLOAD = 'CSVダウンロード',
  EXAMINEES_TABLE_EMPTY_RESULT_MESSAGE = '該当する受験者は存在しません',
  EXAMINEES_TABLE_GROUP = '試験団体',
  EXAMINEES_TABLE_TEST_NAME = '試験名',
  EXAMINEES_TABLE_REGION = '試験会場',
  EXAMINEES_TABLE_TEST_DATETIME = '試験日時',
  EXAMINEES_TABLE_EXAM_NAME = '受験者名',
  EXAMINEES_TABLE_WITH_MARK = 'マーキング',
  EXAMINEES_TABLE_SCORE = '不正点数',
  EXAMINEES_TABLE_RECORD = '保存データ',
  // 「AI解析依頼」画面
  ANALYSIS_REQUEST_PAGE_REQUEST_FORM_REQUEST_NUMBER = '依頼番号',
  ANALYSIS_REQUEST_PAGE_REQUEST_FORM_REQUEST_LEVEL = '解析方法',
  ANALYSIS_REQUEST_PAGE_REQUEST_FORM_REQUEST_LEVEL_01 = '2秒間隔',
  ANALYSIS_REQUEST_PAGE_REQUEST_FORM_REQUEST_LEVEL_02 = '3秒間隔',
  ANALYSIS_REQUEST_PAGE_REQUEST_FORM_REQUEST_LEVEL_03 = '5秒間隔',
  ANALYSIS_REQUEST_PAGE_REQUEST_FORM_REQUEST_LEVEL_04 = '1分間隔',
  ANALYSIS_REQUEST_PAGE_REQUEST_FORM_REQUEST_LEVEL_05 = '3分毎に2秒間隔を10回',
  ANALYSIS_REQUEST_PAGE_REQUEST_FORM_SUBMIT_LABEL = '依頼する',
  ANALYSIS_REQUEST_PAGE_REQUEST_TABLE_HEADER_INSEQ = '依頼番号',
  ANALYSIS_REQUEST_PAGE_REQUEST_TABLE_HEADER_STATUS = 'ステータス',
  ANALYSIS_REQUEST_PAGE_REQUEST_TABLE_HEADER_ENV = '環境',
  ANALYSIS_REQUEST_PAGE_REQUEST_TABLE_HEADER_CREATED_AT = '依頼日',
  ANALYSIS_REQUEST_PAGE_REQUEST_TABLE_HEADER_UPDATED_AT = '更新日',
  ANALYSIS_REQUEST_PAGE_REQUEST_MESSAGE_PLEASE_SELECT_EXAM_USER = '受験者が選択されていません。',
  // 「結果検索」画面
  // 「受験者詳細」画面
  EXAMINEE_DETAIL_TITLE = '受験者詳細',
  EXAMINEE_DETAIL_USER_INFOMATION = '受験者情報',
  EXAMINEE_DETAIL_HEAD_SHOT = '本人写真',
  EXAMINEE_DETAIL_ID_CARD = 'ID写真',
  EXAMINEE_DETAIL_LOGIN_ID = 'ログインID',
  EXAMINEE_DETAIL_SETTING = '設定情報',
  EXAMINEE_DETAIL_LANG = '言語',
  EXAMINEE_DETAIL_IS_MOBILE = 'モバイル利用',
  EXAMINEE_DETAIL_IS_PROCTOR = '監視利用',
  EXAMINEE_DETAIL_IS_RECORD = '録画利用',
  EXAMINEE_DETAIL_IS_AI_AUTH = '認証種類',
  EXAMINEE_DETAIL_IS_AI_FAILED_MANUAL = 'AI認証失敗時の挙動',
  EXAMINEE_DETAIL_IS_AI_IDCARD_REQUEST = 'ID写真の採取',
  EXAMINEE_DETAIL_AI_IDCARD_TYPE = '利用公的証明書',
  EXAMINEE_DETAIL_AI_NAME_MATCH = '氏名照合適合許容率',
  EXAMINEE_DETAIL_IS_DEBUG = 'デバッグオプション',
  EXAMINEE_DETAIL_IS_CONV = '動画変換',
  EXAMINEE_DETAIL_MAXIMAM_RECORDING_TIME = '最長録画時間(秒)',
  EXAMINEE_DETAIL_RECORD_QUARITY = '録画品質',
  EXAMINEE_DETAIL_IS_AUDIO = '音声録音',
  EXAMINEE_DETAIL_AUDIO_QUARITY = '音声品質',
  EXAMINEE_DETAIL_MAXIMAM_CONNECTING_TIME = '最長接続時間(秒)',
  EXAMINEE_DETAIL_IS_SUMMARY = 'サマリ送付有無',
  EXAMINEE_DETAIL_SELECTED_ANALYSIS_TYPES = '解析種類',
  EXAMINEE_DETAIL_EXAM_URL = '外部起動サービスURL',
  EXAMINEE_DETAIL_TEST_INFORMATION = '試験情報',
  EXAMINEE_DETAIL_GROUP = '試験団体',
  EXAMINEE_DETAIL_TEST_NAME = '試験名',
  EXAMINEE_DETAIL_REGION = '試験会場',
  EXAMINEE_DETAIL_TEST_AT = '試験日 / 試験時間',
  EXAMINEE_DETAIL_EXAM_NAME = '受験者名',
  EXAMINEE_DETAIL_R_EXAM_NAME = '受験者名(ローマ字)',
  EXAMINEE_DETAIL_EXAMINEE_LOG_HEADLINE = '受験者ログ',
  EXAMINEE_DETAIL_EXAMINEE_LOG_STATUS = 'ステータス',
  EXAMINEE_DETAIL_EXAMINEE_LOG_AI_NAME_MATCH = '氏名適合率',
  EXAMINEE_DETAIL_EXAMINEE_LOG_LOGIN_TIME = 'ログイン時刻',
  EXAMINEE_DETAIL_EXAMINEE_LOG_MATCHED = 'マッチング済み',
  EXAMINEE_DETAIL_EXAMINEE_LOG_AUTHENTICATED = '本人認証済み',
  EXAMINEE_DETAIL_EXAMINEE_LOG_EXAM_START = '試験開始',
  EXAMINEE_DETAIL_EXAMINEE_LOG_EXAM_END = '試験終了',
  EXAMINEE_DETAIL_EXAMINEE_LOG_LOGOUT = 'ログアウト',
  EXAMINEE_DETAIL_RECORD_LOG_HEADLINE = '動画状態',
  EXAMINEE_DETAIL_RECORD_LOG_STATUS = 'ステータス',
  EXAMINEE_DETAIL_RECORD_LOG_START = '録画開始',
  EXAMINEE_DETAIL_RECORD_LOG_END = '録画終了',
  EXAMINEE_DETAIL_RECORD_LOG_ENCODED = '変換完了',
  EXAMINEE_DETAIL_RECORD_LOG_AI_ANALYSIS_AUTO = 'AI解析完了(自動)',
  EXAMINEE_DETAIL_RECORD_LOG_AI_ANALYSIS_MANUAL = 'AI解析完了(手動)',
  EXAMINEE_DETAIL_EXCHANGES_TITLE = '本人認証時コメント',
  EXAMINEE_DETAIL_MEMO = '受験者関連情報',
  EXAMINEE_DETAIL_MARKING_SUMMARIES = '不正情報一覧',
  EXAMINEE_DETAIL_MARKINGS = '不正報告情報',
  EXAMINEE_DETAIL_AI_MARKING_SUMMARIES = 'AI解析不正情報一覧',
  EXAMINEE_DETAIL_AI_MARKINGS = 'AI解析不正報告情報',
  EXAMINEE_DETAIL_COUNT = '件',
  EXAMINEE_DETAIL_DOWNLOAD = 'ダウンロード',
  EXAMINEE_DETAIL_AI_COUNT_LABEL = 'AI解析不正報告件数：',
  EXAMINEE_DETAIL_AI_SCORE_LABEL = 'AI解析不正点数：',
  EXAMINEE_DETAIL_BUTTONS_BACK = '検索に戻る',
  EXAMINEE_DETAIL_CHANGE_TEST_PASS = '合否設定',
  EXAMINEE_DETAIL_PASS = '合格',
  EXAMINEE_DETAIL_FAILURE = '不合格',
  EXAMINEE_DETAIL_PASS_CONFIRM = '試験結果を書き換えますがよろしいですか',
  EXAMINEE_DETAIL_PASS_SUCCESS = '試験結果の書き換えに成功しました',
  EXAMINEE_DETAIL_PASS_FAILED = '試験結果の書き換えに失敗しました',
  EXAMINEE_DETAIL_GOTO_PAGE_TOP = 'ページトップへ',
  // 「マッチング情報詳細」画面
  EXAMINEE_LOGIN_TITLE = 'マッチング詳細',
  EXAMINEE_LOGIN_DETAIL_HEAD_SHOT = '本人写真',
  EXAMINEE_LOGIN_DETAIL_ID_CARD = 'ID写真',
  EXAMINEE_LOGIN_DETAIL_TARGET = '認証サーバ',
  EXAMINEE_LOGIN_DETAIL_LOGIN_ID = 'ID',
  EXAMINEE_LOGIN_DETAIL_EXAM_NAME = '名前',
  EXAMINEE_LOGIN_DETAIL_R_EXAM_NAME = '受験者名(ローマ字)',
  EXAMINEE_LOGIN_DETAIL_IS_AUTH = '本人認証利用',
  EXAMINEE_LOGIN_DETAIL_IS_MC_STARTUP = 'MC+利用有無',
  EXAMINEE_LOGIN_DETAIL_EXAM_URL = '外部起動サービスURL',
  EXAMINEE_LOGIN_DETAIL_REGION = '会場名',
  EXAMINEE_LOGIN_DETAIL_TEST_NAME = '試験名',
  EXAMINEE_LOGIN_DETAIL_TEST_AT = '試験日時',
  EXAMINEE_LOGIN_DETAIL_IS_PROCTOR = '監視利用',
  EXAMINEE_LOGIN_DETAIL_IS_AI_AUTH = '認証種類',
  EXAMINEE_LOGIN_DETAIL_IS_AI_FAILED_MANUAL = 'AI認証失敗時の挙動',
  EXAMINEE_LOGIN_DETAIL_IS_AI_IDCARD_REQUEST = 'ID写真の採取',
  EXAMINEE_LOGIN_DETAIL_AI_IDCARD_TYPE = '利用公的証明書',
  EXAMINEE_LOGIN_DETAIL_AI_NAME_MATCH = '氏名照合適合許容率',
  EXAMINEE_LOGIN_DETAIL_IS_RECORD = '録画利用',
  EXAMINEE_LOGIN_DETAIL_IS_SUMMARY = 'サマリ送付有無',
  EXAMINEE_LOGIN_DETAIL_LANG = '言語',
  EXAMINEE_LOGIN_DETAIL_MAX_RECTIME = '最長録画時間',
  EXAMINEE_LOGIN_DETAIL_MATCHING_TIMEOUT = 'マッチングタイムアウト時間',
  EXAMINEE_LOGIN_DETAIL_TESTER_RETRY = '受験者側監視者マッチングリトライ回数',
  EXAMINEE_LOGIN_DETAIL_CHECKER_RETRY = '監視者側受験者マッチングリトライ回数',
  EXAMINEE_LOGIN_DETAIL_IS_MOBILE = 'モバイル利用',
  EXAMINEE_LOGIN_DETAIL_WEBRTC_TIMEOUT = 'WebRTCリトライ間隔',
  EXAMINEE_LOGIN_DETAIL_INTERVALTIME = '中断時間目安',
  EXAMINEE_LOGIN_DETAIL_WEBRTC_MAX_TIME = 'WebRTC最長接続時間',
  EXAMINEE_LOGIN_DETAIL_IS_VOICE_RECORDING = '音声録音',
  EXAMINEE_LOGIN_DETAIL_IS_DEBUG = 'デバッグオプション',
  EXAMINEE_LOGIN_DETAIL_IS_CONV = '動画変換',
  EXAMINEE_LOGIN_DETAIL_VIDEO_RECORDING_PREFERENCE = '録画品質',
  EXAMINEE_LOGIN_DETAIL_VOICE_QUALITY_PREFERENCE = '録音品質',
  EXAMINEE_LOGIN_DETAIL_FORGET_PASSWORD = 'パスワード忘れURL',
  EXAMINEE_LOGIN_DETAIL_MEMO = '申送事項',
  EXAMINEE_LOGIN_DETAIL_MESSAGES_IDENTIFICATION = '本人確認ログ',
  EXAMINEE_LOGIN_DETAIL_MESSAGES_MONITORING = '監視ログ',
  EXAMINEE_LOGIN_DETAIL_EXAMINEE_LOG_HEADLINE = '受験者ログ',
  EXAMINEE_LOGIN_DETAIL_EXAMINEE_LOG_STATUS = 'ステータス',
  EXAMINEE_LOGIN_DETAIL_EXAMINEE_LOG_AI_NAME_MATCH = '氏名適合率',
  EXAMINEE_LOGIN_DETAIL_EXAMINEE_LOG_LOGIN_TIME = 'ログイン時刻',
  EXAMINEE_LOGIN_DETAIL_EXAMINEE_LOG_MATCHED = 'マッチング済み',
  EXAMINEE_LOGIN_DETAIL_EXAMINEE_LOG_AUTHENTICATED = '本人認証済み',
  EXAMINEE_LOGIN_DETAIL_EXAMINEE_LOG_EXAM_START = '試験開始',
  EXAMINEE_LOGIN_DETAIL_EXAMINEE_LOG_EXAM_END = '試験終了',
  EXAMINEE_LOGIN_DETAIL_EXAMINEE_LOG_LOGOUT = 'ログアウト',
  EXAMINEE_LOGIN_DETAIL_RECORD_LOG_HEADLINE = '動画状態',
  EXAMINEE_LOGIN_DETAIL_RECORD_LOG_STATUS = 'ステータス',
  EXAMINEE_LOGIN_DETAIL_RECORD_LOG_START = '録画開始',
  EXAMINEE_LOGIN_DETAIL_RECORD_LOG_END = '録画終了',
  EXAMINEE_LOGIN_DETAIL_RECORD_LOG_ENCODED = '変換完了',
  EXAMINEE_LOGIN_DETAIL_RECORD_LOG_AI_ANALYSIS_AUTO = 'AI解析完了(自動)',
  EXAMINEE_LOGIN_DETAIL_RECORD_LOG_AI_ANALYSIS_MANUAL = 'AI解析完了(手動)',
  EXAMINEE_LOGIN_DETAIL_BUTTONS_BACK = '戻る',
  // 管理画面「監視者マッチング状況」画面
  CHECKERS_MONITORING = '監視者マッチング状況',
  CHECKERS_MONITORING_PAGE_TITLE = 'マッチング状況モニタリング',
  CHECKERS_MONITORING_MATCHING_LIST_SUMMARY = '監視者一覧',
  CHECKERS_MONITORING_MATCHING_LIST_HEADER_TEXT_MONITOR = '監視者',
  CHECKERS_MONITORING_MATCHING_LIST_HEADER_TEXT_TESTER_01 = '受験者1',
  CHECKERS_MONITORING_MATCHING_LIST_HEADER_TEXT_TESTER_02 = '受験者2',
  CHECKERS_MONITORING_MATCHING_LIST_HEADER_TEXT_TESTER_03 = '受験者3',
  CHECKERS_MONITORING_MATCHING_LIST_HEADER_TEXT_TESTER_04 = '受験者4',
  CHECKERS_MONITORING_MATCHING_LIST_HEADER_TEXT_TESTER_05 = '受験者5',
  CHECKERS_MONITORING_NOT_MATCHING_LIST_SUMMARY = '未割り当て受験者',
  CHECKERS_MONITORING_KICKED_USER_LIST_SUMMARY = '強制退出済み受験者',
  CHECKERS_MONITORING_NOT_MATCHING_LIST_DELETE_BUTTON_LABEL = '解除',
  CHECKERS_MONITORING_NOT_MATCHING_LIST_HEADER_TEXT_EXAM = '受験者',
  CHECKERS_MONITORING_NOT_MATCHING_LIST_HEADER_TEXT_STATUS = '状態',
  CHECKERS_MONITORING_NOT_MATCHING_LIST_HEADER_TEXT_AUTH_CHECK = '本人確認',
  CHECKERS_MONITORING_NOT_MATCHING_LIST_HEADER_TEXT_USE_MC_PLUS = 'MC+利用',
  CHECKERS_MONITORING_NOT_MATCHING_LIST_HEADER_TEXT_EXAM_URL = '外部サービス',
  CHECKERS_MONITORING_NOT_MATCHING_LIST_HEADER_TEXT_IS_PROCTOR = '監視利用',
  CHECKERS_MONITORING_NOT_MATCHING_LIST_HEADER_TEXT_IS_RECORD = '録画利用',
  CHECKERS_MONITORING_NOT_MATCHING_LIST_HEADER_TEXT_IS_SUMMARY = 'サマリ送付',
  CHECKERS_MONITORING_NOT_MATCHING_LIST_HEADER_TEXT_MEMO = '申し送り事項',
  CHECKERS_MONITORING_NOT_MATCHING_LIST_MATCHING_STATE_WAIT = '割当待ち',
  CHECKERS_MONITORING_NOT_MATCHING_LIST_MATCHING_STATE_ON_HOLD = '保留',
  // アクセス制限
  ACCESS_LIMIT = 'IPアクセス制限',
  ACCESS_LIMIT_TITLE = 'IPアクセス制限',
  ACCESS_LIMIT_TABLE_FORM_LABEL_SERVER = '認証サーバー',
  ACCESS_LIMIT_TABLE_FORM_LABEL_IP = 'IPアドレス',
  ACCESS_LIMIT_TABLE_FORM_LABEL_DOMAIN = 'ドメイン',
  ACCESS_LIMIT_TABLE_FORM_LABEL_ACTOR = '対象アクタ',
  ACCESS_LIMIT_TABLE_HEADER_TITLE_SERVER = '認証サーバー',
  ACCESS_LIMIT_TABLE_HEADER_TITLE_IP = 'IPアドレス',
  ACCESS_LIMIT_TABLE_HEADER_TITLE_DOMAIN = 'ドメイン',
  ACCESS_LIMIT_TABLE_HEADER_TITLE_ACTOR = '対象アクタ',
  ACCESS_LIMIT_INSERT_BUTTON_LABEL = '登録',
  ACCESS_LIMIT_DELETE_BUTTON_LABEL = '削除',
  // ログ検索画面
  LOGVIEW_TITLE = 'ログ検索',
  // ログイン者一覧
  LOGINS_TITLE = 'ログイン者一覧',
  LOGINS_FORM_ACTOR_KIND_TESTER = '受験者',
  LOGINS_FORM_ACTOR_KIND_OTHER = '受験者以外',
  LOGINS_FORM_ACTOR_KIND_ALL = 'すべて',
  LOGINS_FORM_SUBMIT_LABEL = '検索',
  LOGINS_TABLE_TH_LOGIN_ID = 'ログインID',
  LOGINS_TABLE_TH_GROUP_ID = '団体ID',
  LOGINS_TABLE_TH_ACTOR = '種別',
  LOGINS_TABLE_TH_TARGET = '認証サーバ',
  LOGINS_TABLE_TH_IS_MC_STARTUP = 'MC利用',
  LOGINS_TABLE_TH_STATUS = 'ステータス',
  LOGINS_TABLE_TH_KICKED = '強制ログアウト',
  LOGINS_TABLE_TH_REJECTED = '認証不可',
  LOGINS_TABLE_TH_UPDATED_AT = '更新日時',
  LOGINS_TABLE_EMPTY_RESULT_MESSAGE = '該当するユーザは存在しません',
  // 削除予定一覧画面
  DELETE_PLAN_TITLE = '削除予定一覧',
  DELETE_PLAN_FORM_DELETE_PLAN_KEY_TESTER = '受験者',
  DELETE_PLAN_FORM_DELETE_PLAN_KEY_RECORD = '動画',
  DELETE_PLAN_FORM_CSV_DOWNLOAD = 'CSVダウンロード',
  // 削除設定画面
  DELETE_SETTING_TITLE = '削除予定設定',
  DELETE_SETTING_SYSTEM_TITLE = 'システム関連',
  DELETE_SETTING_GROUP_TITLE = '団体',
  DELETE_SETTING_TABLE_HEADER_KIND = '種類',
  DELETE_SETTING_TABLE_HEADER_DURATION = '保持期間',
  DELETE_SETTING_TABLE_HEADER_UPDATED_AT = '更新日',
  DELETE_SETTING_SPECIAL_GROUP_ID_SYSTEM_LOG = 'システムログ',
  DELETE_SETTING_SPECIAL_GROUP_ID_MONITOR_HISTORY = '監視者履歴',
  DELETE_SETTING_SPECIAL_GROUP_ID_DEFAULT_SETTING = 'デフォルト設定',
  DELETE_SETTING_DURATION = '日間',
  DELETE_SETTING_ADD_BUTTON_LABEL = '追加',
  DELETE_SETTING_UPDATE_BUTTON_LABEL = '更新',
  DELETE_SETTING_CREATE_MODAL_TITLE = '追加',
  DELETE_SETTING_EDIT_MODAL_TITLE = '更新',
  DELETE_SETTING_POST_REGISTER_BUTTON_LABEL = '登録',
  DELETE_SETTING_POST_UPDATE_BUTTON_LABEL = '更新',
  DELETE_SETTING_CLOSE_BUTTON_LABEL = '閉じる',
  DELETE_SETTING_GROUP_ID_LABEL = '団体',
  DELETE_SETTING_DURATION_LABEL = '保存期限',
  DELETE_SETTING_CREATE_SUCCESS_MESSAGE = '登録に成功しました',
  DELETE_SETTING_CREATE_ERROR_MESSAGE = '登録に失敗しました',
  DELETE_SETTING_EDIT_SUCCESS_MESSAGE = '更新に成功しました',
  DELETE_SETTING_EDIT_ERROR_MESSAGE = '更新に失敗しました',

  // 短縮URL登録一覧
  CREATE_SHORTURL_LIST_TITLE = 'URL登録一覧',
  CREATE_SHORTURL_LIST_INSERT_BUTTON_LABEL = '登録',
  CREATE_SHORTURL_LIST_DELETE_BUTTON_LABEL = '削除',
  CREATE_SHORTURL_LIST_LOGIN_CATEGORY = 'ログイン種別',
  CREATE_SHORTURL_LIST_URLKEY = 'URLキー',
  CREATE_SHORTURL_LIST_SERVER = '認証サーバー',
  CREATE_SHORTURL_CHANGE_DATE = '変更日',
  CREATE_SHORTURL_LIST_CHANGE_BUTTON_LABEL = '変更',

  // 短縮URL登録
  CREATE_SHORTURL_TITLE = 'URL登録',
  CREATE_SHORTURL_URLKEY = 'URLキー',
  CREATE_SHORTURL_SERVER = '認証サーバー',
  CREATE_SHORTURL_PLACEHOLDER = 'https://FQDN(最後の”/”不要)',
  CREATE_SHORTURL_LOGIN_CATEGORY = 'ログイン種別',
  CREATE_SHORTURL_TESTER = '受験者',
  CREATE_SHORTURL_MONITOR_GROUP_MANAGER = '監視者・団体管理者・システム管理者',
  CREATE_SHORTURL_LANG = '言語',
  CREATE_SHORTURL_JAPANESE = '日本語',
  CREATE_SHORTURL_ENGLISH = '英語',
  CREATE_SHORTURL_CHINESE = '中国語',
  CREATE_SHORTURL_IS_MOBILE = 'モバイル利用',
  CREATE_SHORTURL_IS_MC_STARTUP = 'MC+利用有無',
  CREATE_SHORTURL_IS_PROCTOR = '監視利用',
  CREATE_SHORTURL_IS_AUTH = '本人認証利用',
  CREATE_SHORTURL_EXAM_URL = '外部起動サービス',
  CREATE_SHORTURL_EXAM_URL_PLACEHOLDER = 'https://起動URL',
  CREATE_SHORTURL_REGION = '会場名',
  CREATE_SHORTURL_TEST_NAME = '試験名',
  CREATE_SHORTURL_TEST_DATETIME = '試験日時',
  CREATE_SHORTURL_MC_NOT_USE = '※MC利用有無が利用しないの時のみ指定',
  CREATE_SHORTURL_IS_VOICE_RECORDING = '音声録音',
  CREATE_SHORTURL_IS_RECORD = '録画利用',
  CREATE_SHORTURL_IS_SUMMARY = 'サマリ送付有無',
  CREATE_SHORTURL_MAXIMAM_RECORDING_TIME = '最長録画時間(秒)',
  CREATE_SHORTURL_NUM_ONLY = '※数値のみ',
  CREATE_SHORTURL_MATCHING_TIMEOUT = 'マッチングタイムアウト時間(秒)',
  CREATE_SHORTURL_TESTER_RETRY = '受験者側監視者(マッチングリトライ回数)',
  CREATE_SHORTURL_CHECKER_RETRY = '監視者側受験者(マッチングリトライ回数)',
  CREATE_SHORTURL_WEBRTC_TIMEOUT = 'WebRTCリトライ間隔(秒数)',
  CREATE_SHORTURL_INTERVALTIME = '中断時間目安(分)',
  CREATE_SHORTURL_WEBRTC_MAX_TIME = 'WebRTC最長接続時間(秒)',
  CREATE_SHORTURL_DEBUG_OPTION = 'デバックオプション',
  CREATE_SHORTURL_OFF = 'off',
  CREATE_SHORTURL_IS_CONV = '動画変換',
  CREATE_SHORTURL_VIDEO_RECORDING_PREFERENCE = '録画品質',
  CREATE_SHORTURL_VOICE_QUALITY_PREFERENCE = '録音品質',
  CREATE_SHORTURL_ANALYSIS_TYPE = '解析種類',
  CREATE_SHORTURL_EYE_ROT = 'eye_rot',
  CREATE_SHORTURL_OTHER_PERSON = 'other_person',
  CREATE_SHORTURL_PEOPLE_NUM = 'people_num',
  CREATE_SHORTURL_VOICE = 'voice',
  CREATE_SHORTURL_FORGET_PASSWORD = 'パスワード忘れURL',
  CREATE_SHORTURL_OPTION_500 = '※オプション 500文字まで',
  CREATE_SHORTURL_MEMO = '申送事項',
  CREATE_SHORTURL_EXAMINING_TERM = '注意事項 (/examining)',
  CREATE_SHORTURL_MODE = 'モード',
  CREATE_SHORTURL_MODE_WORD = '※ログイン種別が受験者以外の場合のみ選択',
  CREATE_SHORTURL_OPTION_30 = '※オプション 30文字まで',
  CREATE_SHORTURL_AI_AUTH = '認証種類',
  CREATE_SHORTURL_MONITOR = '監視者',
  CREATE_SHORTURL_AI = 'AI',
  CREATE_SHORTURL_AI_FAILED_MANUAL = 'AI認証失敗時の挙動',
  CREATE_SHORTURL_AI_FORCED_LOGOUT = '強制ログアウト',
  CREATE_SHORTURL_PROCTOR_AUTH = '監視者認証',
  CREATE_SHORTURL_COLLECT_ID_IMAGE = 'ID画像を採取',
  CREATE_SHORTURL_FACE_RETRIE_TIME = '顔撮影リトライ回数',
  CREATE_SHORTURL_ID_RETRIE_TIME = 'ID撮影リトライ回数',
  CREATE_SHORTURL_ALL_RETRIE_TIME = '全体リトライ回数',
  CREATE_SHORTURL_NAME_MATCHING_TOLERANCE = '名前照合適合許容率',
  CREATE_SHORTURL_NUM_ONLY_1_TO_100 = '※数値のみ 1～100までの数値',
  CREATE_SHORTURL_PUBLIC_CERTIFICATION = '利用公的認証種類',
  CREATE_SHORTURL_REQUIRED = '※いずれか一つは必須指定',
  CREATE_SHORTURL_CAPTURE_INTERVAL = 'キャプチャー間隔(秒)',
  CREATE_SHORTURL_NUM_ONLY_0_TO_3600 = '※数値のみ 0～3600までの数値',
  CREATE_SHORTURL_CAPTURE_ANALYSIS_TYPE = 'キャプチャー解析種類',
  CREATE_SHORTURL_NO_AI_CHECK = 'AIチェックなし',
  CREATE_SHORTURL_AI_REALTIME_CHECK = 'AIリアルタイムチェック',
  CREATE_SHORTURL_AI_AFTER_CHECK = 'AI後解析',
  CREATE_SHORTURL_CAPTURE_IMAGE_DISPLAY = '監視画面キャプチャー画像表示',
  CREATE_SHORTURL_DO_NOT_SHOW = '表示しない',
  CREATE_SHORTURL_DO_SHOW = '表示する',
  CREATE_SHORTURL_REGISTER = '登録',

  // 管理画面トップメニュー
  MANAGEMENT_MENU = 'メニュー',
  MANAGEMENT_EXAMINEES = '結果検索',
  MANAGEMENT_CHECKERS_MONITORING = '監視者マッチング状況',
  MANAGEMENT_ANALYSIS_REQUEST = 'データ抽出',
  MANAGEMENT_ACCESS_LIMIT = 'IPアクセス制限',
  MANAGEMENT_LOGVIEW = 'ログ参照',
  MANAGEMENT_SHORT_URL = 'URLエントリ',
  MANAGEMENT_TERM_EDIT = '注意事項、利用規約など編集',
  MANAGEMENT_SUMMARY = 'サマリー',
  MANAGEMENT_SUMMARY_LOGIN_COUNT = 'ログイン者数',
  MANAGEMENT_SUMMARY_LOGIN_COUNT_TESTER = '受験者',
  MANAGEMENT_SUMMARY_LOGIN_COUNT_OTHER = '受験者以外',
  MANAGEMENT_SUMMARY_DELETE_PLAN = '本日削除予定',
  MANAGEMENT_SUMMARY_DELETE_PLAN_SETTING = '設定',
  MANAGEMENT_SUMMARY_DELETE_PLAN_TESTER = '受験者',
  MANAGEMENT_SUMMARY_DELETE_PLAN_RECORD = '動画',
  // 単語
  WORD_USE = '利用する',
  WORD_NOT_USE = '利用しない',
  WORD_DO = 'する',
  WORD_DONT_DO = 'しない',
  WORD_EXISTS = 'あり',
  WORD_NOT_EXISTS = 'なし',
  WORD_SECONDS = '秒',
  WORD_MINUTES = '分',
  WORD_PROCTOR = '監視員',
  WORD_AI = 'AI',
  WORD_FORCE_LOGOUT = '強制ログアウト',
  WORD_PROCTOR_AUTH = '監視員認証',
  WORD_CONTINUE = '続行',
  WORD_DRIVERS_LICENSE = '運転免許証',
  WORD_PASSPORT = 'パスポート',
  WORD_MY_NUMBER_CARD = 'マイナンバーカード',
  WORD_EXAMINEE_LOG_STATUS_LOGIN = 'ログイン',
  WORD_EXAMINEE_LOG_STATUS_MATCHED = 'マッチング済み',
  WORD_EXAMINEE_LOG_STATUS_AUTHENTICATED = '本人認証済み',
  WORD_EXAMINEE_LOG_STATUS_EXAM_START = '試験開始',
  WORD_EXAMINEE_LOG_STATUS_EXAM_END = '試験終了',
  WORD_EXAMINEE_LOG_STATUS_EXAM_LOGOUT = 'ログアウト',
  WORD_RECORD_LOG_STATUS_00 = '未変換',
  WORD_RECORD_LOG_STATUS_01 = '変換中',
  WORD_RECORD_LOG_STATUS_02 = '変換完了',
  WORD_RECORD_LOG_STATUS_03 = '解析未処理',
  WORD_RECORD_LOG_STATUS_04 = '解析依頼処理中',
  WORD_RECORD_LOG_STATUS_05 = '解析中',
  WORD_RECORD_LOG_STATUS_06 = '解析完了',
  WORD_RECORD_LOG_STATUS_07 = '解析エラー',
  WORD_RECORD_LOG_STATUS_08 = '変換エラー',
  WORD_EXAM_LOGINS_STATUS_01 = 'ログイン済み',
  WORD_EXAM_LOGINS_STATUS_02 = 'マッチング済み',
  WORD_EXAM_LOGINS_STATUS_03 = '本人認証済み',
  WORD_EXAM_LOGINS_STATUS_04 = '受験前',
  WORD_EXAM_LOGINS_STATUS_05 = '受験中',
  WORD_EXAM_LOGINS_STATUS_06 = '受験中断',
  WORD_EXAM_LOGINS_STATUS_07 = '受験終了',
  WORD_J_ZEN = '全',
  WORD_J_KEN_TYUU = '件中',
  WORD_J_KEN = '件',
  WORD_J_KARA = '〜',
  WORD_J_WO_HYOUJI = 'を表示',
  WORD_CSV_SEARCH_ERROR = '受験者の検索に失敗しました',
  WORD_CSV_DOWNLOAD_ERROR = 'CSVのダウンロードに失敗しました',
  WORD_ANALYSIS_TYPE_01 = 'eye_rot',
  WORD_ANALYSIS_TYPE_02 = 'other_person',
  WORD_ANALYSIS_TYPE_03 = 'people_num',
  WORD_ANALYSIS_TYPE_04 = 'voice',
  // モーダル
  MODAL_CONFIRM_TITLE_DEFAULT = '確認',
  MODAL_SUCCESS_TITLE_DEFAULT = '成功',
  MODAL_ERROR_TITLE_DEFAULT = '失敗',
  MODAL_CAUTION_TITLE_DEFAULT = '注意',
  MODAL_BUTTON_LABEL_OK = '　OK　',
  MODAL_BUTTON_LABEL_CANCEL = 'キャンセル',
  MODAL_BUTTON_LABEL_CLOSE = '閉じる',
  // 500エラー
  INTERNAL_SERVER_ERROR_ALERT_TEXT = 'システムエラーが発生しました',
  // 4xxエラー
  BAD_REQUEST_ALERT_TEXT = '本ウインドウを閉じて、再度ログイン画面からログインしてください。',
}

// export namespace JapaneseLanguageEnum {
//   export const ENV_SETTING_TERM_OF_USE_TEXT = ENV_SETTING_TERM_OF_USE_TEXT_JA.trim();
//   export const ENV_SETTING_NOTES_AND_AGREEMENTS_TEXT = ENV_SETTING_NOTES_AND_AGREEMENTS_TEXT_JA.trim();
// }

export enum EnglishLanguageEnum {
  // COMMON
  FOOTER_TERM = 'term',
  ACTOR_TESTER = '',
  ACTOR_CHECKER = '',
  ACTOR_TEST_MANAGER = '',
  ACTOR_SYSTEM_MANAGER = '',
  ACTOR_SUPERVISOR = '',
  FORCE_LOGOUT = '',
  FORCE_CLOSE_PAGE = '',
  // ログイン画面
  LOGIN_ID = 'ログインID',
  LOGIN_PASSWORD = 'パスワード',
  LOGIN_USE_CAMERA_DEVICE = '利用カメラデバイス：',
  LOGIN_NOT_EXISTS_CAMERA_DEVICE = 'カメラデバイスはありません',
  // カメラ・マイク設定画面
  ENV_SETTING_CAMERA_CHECK = '',
  ENV_SETTING_MICROPHONE_CHECK = '',
  ENV_SETTING_POPUP_CHECK = '',
  ENV_SETTING_NEXT = '',
  ENV_SETTING_NETWORK_ERROR = '',
  ENV_SETTING_TERM_OF_USE = '',
  ENV_SETTING_AGREE_TERM_OF_USE = '',
  ENV_SETTING_RECONFIRM = '',
  // 本人認証画面
  IDENTIFICATION_AFTER_MATCHNG = '',
  // AI本人認証画面
  AI_IDENTIFICATION_AUTH_IMAGE = '',
  AI_IDENTIFICATION_ID_IMAGE = '',
  AI_IDENTIFICATION_SHOOT = '',
  AI_IDENTIFICATION_REGISTRATION_ALERT = '',

  // AI本人認証画面 - チェックリスト固定文言
  AI_IDENTIFICATION_FIXED_STR_1 = '',
  AI_IDENTIFICATION_FIXED_STR_2 = '',
  AI_IDENTIFICATION_FIXED_STR_3 = '',
  AI_IDENTIFICATION_FIXED_STR_4 = '',
  AI_IDENTIFICATION_FIXED_STR_5 = '',
  AI_IDENTIFICATION_FIXED_STR_6 = '',
  AI_IDENTIFICATION_FIXED_STR_7 = '',
  AI_IDENTIFICATION_FIXED_STR_8 = '',
  AI_IDENTIFICATION_FIXED_STR_9 = '',

  // AI本人認証画面 - 注意書き（固定文言）
  AI_IDENTIFICATION_FIXED_NOTE_1 = '',
  AI_IDENTIFICATION_FIXED_NOTE_2 = '',
  AI_IDENTIFICATION_FIXED_NOTE_3 = '',
  AI_IDENTIFICATION_FIXED_NOTE_4 = '',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_1 = '',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_2 = '',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_3 = '',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_4 = '',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_5 = '',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_6 = '',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_7 = '',
  AI_IDENTIFICATION_FIXED_NOTE_ERROR_8 = '',
  AI_IDENTIFICATION_FIXED_NOTE_SUBSTR_1 = '',
  AI_IDENTIFICATION_FIXED_NOTE_SUBSTR_2 = '',
  AI_IDENTIFICATION_FIXED_NOTE_SUBSTR_3 = '',

  // 500エラー
  INTERNAL_SERVER_ERROR_ALERT_TEXT = '',
  // 4xxエラー
  BAD_REQUEST_ALERT_TEXT = '',

  // 利用規約画面
  TERMS_TITLE = '',
  // 試験中画面
  EXAMINING_TERM = '注意事項',
  EXAMINING_TERM_EXP_TEXT = '以下の行為は不正行為とみなされる可能性がありますのでご注意ください。',
  EXAMINING_TERM_NOT_HEADER = '受験開始の画面の注意事項',
  EXAMINING_TERM_NOTES = `
試験開始から終了まで、席を離れることはできません。
受験中は、ヘッドフォン、イヤホンは禁止です。
試験監督からの指示がある場合は音声ではなく、画面に表示されます。
試験監督から画面上に指示があった場合は、その指示に従ってください。
受験中に試験監督から持ち込み可能物、使用可能物に関しての指摘があった場合、試験実施団体から許可されているものである場合はその旨をマイクを通じて申告してください。
受験者からの音声は、試験官は聞くことができます。
受験中に、受験者から試験官に連絡をとることはできません。
試験官は常時監視しているとは限りませんが、後日録画映像を確認することで不正の有無を確認します。
疑義があった場合など確認のため後日連絡させていただくことがあります。
試験終了時に試験官からの指示は特にありません。試験画面に終了メッセージが表示されたら、画面の案内に従い、画面を閉じて終了してください。
  `,
  EXAMINING_TERM_AGREE = '上記注意事項に同意します',
  EXAMINING_ALERT_LABEL_TEXT = '監視中です。この画面は閉じないでください。',
  EXAMINING_LOGIN_TEST_SYSTEM = '試験システムへログイン',
  EXAMINING_BEFORE_RECORDING = '待機中',
  EXAMINING_AFTER_RECORDING = '録画終了',
  EXAMINING_END = '試験終了',
  EXAMINING_CLOSE = 'このタブを閉じてください',
  // 試験終了画面
  // 監視画面
  MONITOR_TESTER_STATUS_LOGIN = 'ログイン済',
  MONITOR_TESTER_STATUS_MATCHED = 'マッチング済',
  MONITOR_TESTER_STATUS_IDENTIFICATED = '本人認証済',
  MONITOR_TESTER_STATUS_BEFORE_EXAM = '試験前',
  MONITOR_TESTER_STATUS_EXAMING = '試験中',
  MONITOR_TESTER_STATUS_INTERRUPTION_EXAM = '試験中断',
  MONITOR_TESTER_STATUS_FINISH_EXAM = '試験終了',
}

// export namespace EnglishLanguageEnum {
//   export const ENV_SETTING_TERM_OF_USE_TEXT = ENV_SETTING_TERM_OF_USE_TEXT_EN.trim();
//   export const ENV_SETTING_NOTES_AND_AGREEMENTS_TEXT = ENV_SETTING_NOTES_AND_AGREEMENTS_TEXT_EN.trim();
// }

export type LanguageEnum = typeof JapaneseLanguageEnum | typeof EnglishLanguageEnum;
